import React from "react";
import { Button } from "../../globalStyles";
import { GiCrystalBars } from "react-icons/gi";
import { GiCutDiamond, GiRock } from "react-icons/gi";
import { IconContext } from "react-icons/lib";
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature,
} from "./Pricing.elements";

const Pricing = () => {
  return (
    <IconContext.Provider value={{ color: "#a9b3c1", size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>Our Services</PricingHeading>
          <PricingContainer>
            {/* <PricingCard>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiRock />
                </PricingCardIcon>
                <PricingCardPlan>Basic Plan</PricingCardPlan>
                <PricingCardCost>$9.99</PricingCardCost>
                <PricingCardLength>per month</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>10GB Storage</PricingCardFeature>
                  <PricingCardFeature>Basic Editing Tools</PricingCardFeature>
                  <PricingCardFeature>Limited Support</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Upcomming Plan</Button>
              </PricingCardInfo>
            </PricingCard> */}
            <PricingCard>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiCrystalBars />
                </PricingCardIcon>
                <PricingCardPlan>Upcoming Plan</PricingCardPlan>{" "}
                <PricingCardCost>Coming Soon</PricingCardCost>{" "}
                <PricingCardLength>per month</PricingCardLength>{" "}
                <PricingCardFeatures>
                  <PricingCardFeature>50GB Storage</PricingCardFeature>
                  <PricingCardFeature>
                    Advanced Editing Tools
                  </PricingCardFeature>
                  <PricingCardFeature>Priority Support</PricingCardFeature>
                </PricingCardFeatures>
                <Button primary disabled>
                  Coming Soon
                </Button>{" "}
              </PricingCardInfo>
            </PricingCard>

            {/* <PricingCard to="https://app.mediamixer.ai/auth/registration">
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiCutDiamond />
                </PricingCardIcon>
                <PricingCardPlan>Ultimate Plan</PricingCardPlan>
                <PricingCardCost>$99.99</PricingCardCost>
                <PricingCardLength>per month</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Unlimited Storage</PricingCardFeature>
                  <PricingCardFeature>
                    Full Suite of Editing Tools
                  </PricingCardFeature>
                  <PricingCardFeature>
                    24/7 Dedicated Support
                  </PricingCardFeature>
                </PricingCardFeatures>
                <Button primary>Upcomming Plan</Button>
              </PricingCardInfo>
            </PricingCard> */}
          </PricingContainer>
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
};

export default Pricing;
