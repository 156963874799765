import React from "react";
import { Button, SubmitButton } from "../../globalStyles";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  Form,
  FormInput,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  TextArea,
} from "./Footer.elements";
import MediaMixerLogo from "../../images/Mediamixer_logo.png";

function Footer() {
  const date = new Date();

  return (
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>Contact Us</FooterSubHeading>
        <FooterSubText>
          Have any questions or feedback? Reach out to us!
        </FooterSubText>
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormInput name="name" type="text" placeholder="Name" />
          <FormInput name="email" type="email" placeholder="Email" />
          <FormInput name="phone" type="tel" placeholder="Phone" />
          <TextArea name="message" type="textarea" placeholder="Message" />
          <SubmitButton fontBig>Submit</SubmitButton>
        </Form>
      </FooterSubscription>
      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>About Us</FooterLinkTitle>
            <FooterLink to="#">
              How it works
            </FooterLink>
            <FooterLink to="#">Terms of Service</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Contact Us</FooterLinkTitle>
            <FooterLink to="#">Contact</FooterLink>
            <FooterLink to="#">Support</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterLinksContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to="#">
            <SocialIcon src={MediaMixerLogo} alt="s" />
          </SocialLogo>
          <WebsiteRights>MEDIAMIXER © {date.getFullYear()} </WebsiteRights>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
