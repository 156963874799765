// export const homeObjOne = {
//   lightBg: false,
//   lightText: true,
//   lightTopLine: true,
//   lightTextDesc: true,
//   topLine: "View Our Products",
//   headline: "Shop through our catalog of products",
//   description:
//     "We provide worldwide shipping to all countries. If there are any issues, just issue a refund and we will process your request",
//   buttonLabel: "Shop Now",
//   imgStart: "",
//   img: require("../../images/svg-1.svg"),
//   alt: "Credit Card",
// };
export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "Experience Media Like Never Before",
  headline: "Unlock Your Creative Potential with MediaMixer",
  description:
    "MediaMixer is your all-in-one solution for multimedia editing and playback. Whether you're a professional content creator or a casual user, MediaMixer offers powerful tools and intuitive features to enhance your media experience.",
  buttonLabel: "Explore MediaMixer",
  imgStart: "",
  img: require("../../images/images3.png"),
  alt: "MediaMixer Illustration",
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: "100% Secure",
  headline: "Secure Your Media Anytime, Anywhere",
  description:
    "MediaMixer prioritizes your security, providing 24/7 protection for your media assets no matter where you are. With our advanced security measures and global network, you can trust that your media is always safe and secure.",
  buttonLabel: "Learn More",
  imgStart: "start",
  img: require("../../images/image2.png"),
  alt: "Vault",
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Easy Setup",
  headline: "Super fast and simple onboarding process",
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: "Start Now",
  imgStart: "start",
  img: require("../../images/images4.png"),
  alt: "Vault",
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "DATA ANALYTICS",
  headline: "Every transaction is stored on our secure cloud database",
  description:
    "Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
  buttonLabel: "Sign Up Now",
  imgStart: "start",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};
