import React from "react";
import { Footer, InfoSection, Pricing } from "../../components";
import { homeObjOne, homeObjTwo, homeObjFour } from "./Data";
import { homeObjOne as homeProductObj, homeObjThree } from "../Products/Data";
import { Navbar } from "../../components";

const Home = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    window.scrollTo({
      behavior: "smooth",
      top: element.offsetTop,
    });
  };
  return (
    <>
      <Navbar handleScroll={scrollToSection} />
      <div id="home">
        <InfoSection {...homeObjOne} />
      </div>
      <div id="homeTwo">
        <InfoSection {...homeObjTwo} />
      </div>

      <div id="products">
        <InfoSection {...homeProductObj} />
      </div>
      {/* <div id="pricing">
        <Pricing />
      </div> */}
      <div id="services">
        <InfoSection {...homeObjFour} />
      </div>
      <div id="setup">
        <InfoSection {...homeObjThree} />
      </div>
      <div id="contact">
        <Footer />
      </div>
    </>
  );
};

export default Home;
