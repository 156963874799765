export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Unlock Creative Possibilities",
  headline: "Explore MediaMixer - Your Gateway to Creativity",
  description:
    "Discover the power of MediaMixer, a revolutionary multimedia platform that empowers you to unleash your creativity without limitations. With MediaMixer, you can explore a world of possibilities and bring your ideas to life like never before.",
  buttonLabel: "Start Creating",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Credit Card",
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "100% Secure",
  headline: 'Secure Your Media Anytime, Anywhere',
  description:
  'MediaMixer prioritizes your security, providing 24/7 protection for your media assets no matter where you are. With our advanced security measures and global network, you can trust that your media is always safe and secure.',
  buttonLabel: "Learn More",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Seamless Integration",
  headline: "Effortless Integration with MediaMixer",
  description:
    "Experience a seamless onboarding process with MediaMixer. Get everything set up and ready to use in minutes. Simply add your information, and you're ready to explore the endless possibilities of multimedia creation and editing.",
  buttonLabel: "Get Started",
  imgStart: "start",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Data Analytics",
  headline: "Every transaction is stored on our secure cloud database",
  description:
    "Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
  buttonLabel: "Sign Up Now",
  imgStart: "start",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};
