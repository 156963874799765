export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Welcome to MediaMixer",
  headline: "Elevate Your Brand with Expert Media Editing",
  description:
    "At MediaMixer, we specialize in empowering your brand through captivating media editing solutions. Whether you're a seasoned marketing agency or an emerging online business, we're here to fuel your growth and amplify your message.",
  buttonLabel: "Get Started",
  imgStart: "",
  img: require("../../images/image1.png"),
  alt: "Credit Card",
  start: "",
};

export const homeObjTwo = {
  primary: true,
  lightBg: true,
  lightTopLine: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Unlock Your Creative Potential",
  headline: "Why Choose MediaMixer?",
  description:
    "MediaMixer isn’t just a software—it’s a team of passionate individuals dedicated to empowering users like you with the tools and resources to express your creativity to the fullest. With MediaMixer, you gain access to a world-class team of industry experts ready to support you in every step of your multimedia journey.",
  buttonLabel: "Discover MediaMixer",
  imgStart: "",
  img: require("../../images/image2.png"),
  alt: "Vault",
  start: "",
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Sarah Jeni",
  headline:
    "Ultra helped me increase my revenue by over 3X in less than 3 months!",
  description:
    "Their team is wonderful! I can't believe I didn't start working with them earlier.",
  buttonLabel: "View Case Study",
  imgStart: "start",
  img: require("../../images/profile.jpg"),
  alt: "Vault",
  start: "true",
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Our Services",
  headline: "Elevate Your Media Experience",
  description:
    "At MediaMixer, we're committed to handling all your media needs with precision and creativity. Our comprehensive video editing services ensure that your content stands out, whether it's a promotional clip, product demo, or any other visual masterpiece.",
  buttonLabel: "Sign Up Now",
  imgStart: "start",
  img: require("../../images/svg-3.svg"),
  alt: "Vault",
  start: "true",
};
